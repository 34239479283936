import React, { Component } from 'react';
import './feedback.css';
import moment from 'moment';
import 'moment-timezone';
//import CSVDownloadComponent from './csvdownload.component ';
import GridTableComponent from './GridTable/grid.table.component';
//import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import * as XLSX from "xlsx/xlsx.mjs";
import * as XLSXPOPULATE from "xlsx-populate";
import * as FileSaver from "file-saver";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class ROEComponent extends Component{
    blankData= {
        date:{CurrencyTo: undefined, CurrencyFrom: undefined, Processingdate: undefined, Result: undefined}};
   
    boxClass = { happy: 'rating_icon ', average: 'rating_icon ', nothappy:'rating_icon '}
    state = {
        name:"",
        startTime:new Date(),
        stopTime:new Date(),
        fromCurr:"INR",
        toCurr:"",
        textOperated:"",
        recordData:[],
        postArrayWithKey:[],
        dataToProcess:"",
        processedData:"",
        processedArrayData:[],
        rating:"",
    }
    render() 
    { 
        return ( 
           < React.Fragment> 
           <div className="ml-0 pl-0 pr-0 mr-0">
                <div className="mb-2 pb-2 mt-1 pt-2 ml-0 pl-0 pr-0 mr-0 d-flex justify-content-center">
                <span className="col-12 d-flex justify-content-center"> <img className="logo" alt="" src={process.env.PUBLIC_URL + ""}/></span>
                </div>
                <div className="help_us mt-1 pt-2 mb-2 pb-2 ml-0 pl-0 mr-0 pr-0">
                <span className="col-12 ml-0 pl-0 mt-0 mb-0 pr-0 mr-0 d-flex justify-content-center text-center  text-primary text-bold">CONVERT CURRENCY VALUES</span>
                </div>
                <div className="row ml-0 pl-0 mt-3 mb-3 pr-0 mr-1 d-flex justify-content-center">
                <div className="row col-8 ml-0 pl-0 mt-3 mb-0 pr-0 mr-1 d-flex justify-content-center">
                        <div className="col-12 ml-0 pl-0 mt-3 mb-0 pr-0 mr-0 d-flex justify-content-center">
                            <span className="col-lg-6 row pt-0 mt-0 mb-0 pb-0 pr-0 mr-0 pl-1 ml-0">
                                <span className="col-lg-4 d-flex justify-content-center mt-1  pt-1 text-bold">From Date:</span>
                                <span className="col-lg-8 pt-0 mt-0 mb-0 pb-0 pr-0 mr-0 pl-1 ml-0 text-bold">  
                                <DatePicker className=" form-control col-lg-10" dateFormat="dd-MM-yyyy" selected={this.state.startTime} datePickerType="single" popperPlacement="left-start" value={this.state.startTime} name="startTime" onChange={(event)=>this.handlechangeDate(event)}  scrollableYearDropdown showYearDropdown /> 
                                </span>
                            </span>

                            <span className="col-lg-6 row pt-0 mt-0 mb-0 pb-0 pr-0 mr-0 pl-0 ml-0">
                                <span className="col-lg-4 d-flex justify-content-center mt-1  pt-1 text-bold ">To Date:</span>
                                <span className="col-lg-8 pt-0 mt-0 mb-0 pb-0 pr-0 mr-0 pl-1 ml-0 text-bold ">  
                                <DatePicker className="form-control col-lg-10" dateFormat="dd-MM-yyyy" selected={this.state.stopTime} value={this.state.stopTime} popperPlacement="left-start" name="stopTime" onChange={this.handlechangeEndDate} scrollableYearDropdown showYearDropdown /></span>
                            </span>
                        </div>

                        <div className="col-12 ml-0 pl-0 mt-3 mb-0 pr-0 mr-0 d-flex justify-content-center">
                            
                        <span className="col-lg-6 row pt-0 mt-0 mb-0 pb-0 pr-0 mr-0 pl-0 ml-0">
                                        <span className="col-lg-4 d-flex justify-content-center mt-1  pt-1 text-bold ">From Curr:</span>
                                        <span className="control-label text-bold col-8 ml-0 mr-0 pl-0 pr-0">
                                        <select className="form-control col-12 small ml-0 mr-0 mb-2" value={this.state.fromCurr} onChange={this.handleFromCurr}>
                                            <option value="">-</option>
                                            <option value="INR">INR</option>
                                            </select>
                                        </span>
                                </span>

                            <span className="col-lg-6 row pt-0 mt-0 mb-0 pb-0 pr-0 mr-0 pl-0 ml-0">
                        
                                <span className="col-4 d-flex justify-content-center mt-1  pt-1 text-bold ">To Curr:</span>
                                    <span className="control-label text-bold col-8 ml-0 mr-0 pl-0 pr-0">
                                        <select className="form-control col-12 small ml-0 mr-0 mb-2" value={this.state.toCurr} onChange={this.handleToCurr} >
                                            <option value="">-</option>
                                            <option value="AED">AED</option>
                                            <option value="AUD">AUD</option>
                                            <option value="CAD">CAD</option>
                                            <option value="EUR">EUR</option>
                                            <option value="GBP">GBP</option>
                                            <option value="IDR">IDR</option>
                                            <option value="JPY">JPY</option>
                                            <option value="LKR">LKR</option>
                                            <option value="MYR">MYR</option>
                                            <option value="NZD">NZD</option>
                                            <option value="PHP">PHP</option>
                                            <option value="SGD">SGD</option>
                                            <option value="THB">THB</option>
                                            <option value="USD">USD</option>
                                            <option value="VND">VND</option>
                                            <option value="ZAR">ZAR</option>
                                            </select>
                                    </span>
                                </span>
                               
                    </div>
                        
                     <div className="col-12 ml-0 pl-0 mt-3 mb-0 pr-0 mr-0 d-flex justify-content-center">
                            <span className="col-6 text-center rating_cusror" >
                                <button className="col-4 btn btn-primary btn-small" onClick={(event)=>this.convertCurr(this.state.startTime,this.state.stopTime,this.state.fromCurr,this.state.toCurr)} >Convert</button>
                            </span>
                     </div>
            </div>    
            </div>
                 <div className="row ml-0 pl-0 mt-3 pt-0 mb-0 pr-0 mr-1 d-flex justify-content-center">  
                <span className="col-lg-6 mt-2 pt-0 pb-2 ml-1 pl-2 mr-0 pr-0">
                <GridTableComponent cols={this.getColsAll()} data={this.state.recordData}  disable={this.state.disableTable} _this={this} />
                </span>
                </div>
            </div>
                
           </React.Fragment>
        )
       
}

getColsAll = () => {
    return [{ header: "FromCurrency", key: "CurrencyFrom", rowclasses : "text-center", headerclasses: "w-20 text-center"},
       { header: "ToCurrency", key: "CurrencyTo",rowclasses : "text-center", headerclasses: "w-20 text-center" },
       { header: "ProcessingDate", key: "Processingdate", headerclasses: "w-20",rowclasses : "mr-2" },
       { header :"Value",key:"Result",rowclasses : "text-center", headerclasses: "w-15 text-center" },
    ]
 }
handleFromCurr=(e)=>{
    let fromCurr =this.state.fromCurr;
    fromCurr = e.target.value
    console.log(fromCurr)
    this.setState({fromCurr});
          
  }
 handleToCurr=(e)=>{
    let toCurr =this.state.toCurr;;
    toCurr = e.target.value
    console.log(toCurr)
    this.setState({toCurr});
    
   }

handlechangeDate=(date)=>{
      
    const startTime =date;
    console.log(startTime)
    this.setState({startTime});
   }
 handlechangeEndDate=(date)=>{
    const stopTime =date;
    console.log(stopTime)
    this.setState({stopTime});
    
   }
   getFormatStartDate = (val) => {
    let date
      if(val){
       date= moment.tz(val,"Asia/Kolkata").format('DDMMMYY').toUpperCase();
       //date= moment.tz(val,"Asia/Kolkata").format('yyyy-MM-DD');
      }
      else{
       date="";
      } 
    //var date = moment.tz(val,"US/Pacific").format("hh:mm:ss");
    
     return date;
 }
 getFormatEndDate = (val) => {
    let date
      if(val){
      date= moment.tz(val,"Asia/Kolkata").format('DDMMMYY').toUpperCase();
      //date= moment.tz(val,"Asia/Kolkata").format('yyyy-MM-DD');
      }
      else{
       date="";
      } 
    //var date = moment.tz(val,"US/Pacific").format("hh:mm:ss");
    
     return date;
 }



 convertCurr=(startTime,stopTime,fromCurr,toCurr)=>{
    let startTimeN = this.getFormatStartDate(startTime);
    let stopTimeN = this.getFormatEndDate(stopTime);
    let dataToProcess = {"key":fromCurr+"TO"+toCurr+","+startTimeN+"TO"+stopTimeN};
    console.log(dataToProcess);
    
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(dataToProcess),
        headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept' : "application/json"
        } 
    }
    
    fetch(process.env.REACT_APP_ROS_BASE_URL + '/ROE', requestOptions)
        .then(response => response.json())
       // .then(res => {postArray.push(res["value"])})
       .then(res => this.refreshData(res))
        .catch(err => console.error(err));    
        } 
      

refreshData=(res)=>{

let recordData=[];
recordData  = JSON.parse(res.value);
console.log(recordData);

this.setState({recordData})
console.log(recordData.length)
/*datas.forEach((data) =>{
    let recordrawdata = {...this.blankData}
    recordrawdata.CurrencyTo = data.CurrencyTo;
    recordrawdata.CurrencyFrom = data.CurrencyFrom;
    recordrawdata.Processingdate = data.Processingdate;
    recordrawdata.Result = datas.Result;
    recorddata.push(recordrawdata);
  });*/
 
let curdate = new Date();
let curdatestring = this.getFormatStartDate(curdate);
const worksheet = XLSX.utils.json_to_sheet(this.state.recordData);
const workbook = XLSX.utils.book_new();
XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
//let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
//XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
XLSX.writeFile(workbook, "ROE-Export"+curdatestring+".xlsx");


}

}
export default ROEComponent;