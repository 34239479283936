
import { BrowserRouter as Router, Switch,Route,Redirect } from "react-router-dom";
import React, { Component } from 'react';
import './App.css';
import BackendRouterComponent from './components/backendrouter.component';
import FrontendRouterComponent from './components/frontendrouter.component';
class App extends Component {
  render() {
  return (
    <Router>
        <Switch>
          <Redirect from="/" exact to="/frontend/home"/>
          <Route path="/frontend/" component={FrontendRouterComponent}></Route>
          <Route path="/backend" component={BackendRouterComponent}></Route>
        
           </Switch>
      </Router>
  );
  }
}

export default App;
