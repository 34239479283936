import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { BrowserRouter as Redirect} from "react-router-dom";
import PNRProcessingComponent from './pnrprocessing.component';
import HomeComponent from './home.component';
import AddValueComponent from './addvalue.component';
import ROEComponent from './roe.component';
import Navbar from './navbar.component';
import TcsComponent from './tcs.component';

class FrontendRouterComponent extends Component {
  
render() {
      return ( 
      <div className="window-main-screen">
             <div className="navigation">
              <Navbar />
             </div>
            <div>
            <main > 
            <div  style={{minHeight : '45vh',maxHeight : '85vh', overflowX: 'scroll'}}>
            {this.openPage()}
            </div>
            </main>
            </div>
      </div>
       );
   }
   openPage =() => {
    let htmlele=""
    console.log(this.props.location.pathname);
        switch (this.props.location.pathname) {
          case "/frontend/add":
             htmlele=<AddValueComponent/>
             break;
          case "/frontend/roe":
              htmlele=<ROEComponent />
              break;
              case "/frontend/home":
              htmlele=<HomeComponent />
              break;
              case "/frontend/tcs":
                htmlele=<TcsComponent />
                break;
          default: 
                htmlele=<Redirect to="/frontend/home" />
                      
       }
   
    return htmlele
 }

/*   openPage =() => {
    return (
        <Router>
            <Switch>
                <Route path="/frontend/add" component={AddValueComponent}/>
                <Route path="/frontend/home" component={HomeComponent}/>
            </Switch>
        </Router>
    )
   }*/

   
 
}

export default FrontendRouterComponent;