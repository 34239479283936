import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css'
class NavBarComponent extends Component {
  state={
    open:false,
     setOpen:false
 }

handleClick = () => {
    this.state.setOpen=!this.state.open;
};

closeMenu = () => {
    this.state.setOpen=false;
};
render(){
return (
    <nav className="navbar col-lg-12 pl-0">
        <div onClick={this.handleClick()} className="nav-logo ml-0 col-lg-3">
        <Link to="/frontend/home" >
            <img alt="" className="pt-0 mt-0 ml-0 pl-0" src={process.env.PUBLIC_URL + "/assets/images/logo.png"}/>
        </Link>
        
        </div>
        <div className="navigation-links col-lg-6">
        <ul className={this.state.open ? 'nav-links active' : 'nav-links'}>
            <li className="nav-item">
                <Link to="/frontend/home" className="nav-link" onClick={this.closeMenu()}>
                   <b>HOME</b> 
                </Link>
            </li>
            <li className="nav-item">
                <Link to="/frontend/tcs" className="nav-link" onClick={this.closeMenu()}>
                   <b>TCS</b> 
                </Link>
            </li>
            <li className="nav-item">
                <Link to="/frontend/add" className="nav-link" onClick={this.closeMenu()}>
                  <b>ADD VALUE</b>  
                </Link>
            </li>
            <li className="nav-item">
                <Link to="/frontend/roe" className="nav-link" onClick={this.closeMenu()}>
                   <b>ROE</b> 
                </Link>
            </li>
           
        </ul>
        </div>
       
    </nav>
);
}
};

export default NavBarComponent;