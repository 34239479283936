import React, { Component } from 'react';
import './feedback.css';
//import CSVDownloadComponent from './csvdownload.component ';
import GridTableComponent from './GridTable/grid.table.component';
//import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import * as XLSX from "xlsx/xlsx.mjs";
import * as XLSXPOPULATE from "xlsx-populate";
import * as FileSaver from "file-saver";

class PNRProcessingComponent extends Component{
    blankData= {
        AirlineName: undefined, FlightNumber: undefined, CabinClass: undefined, Amount: undefined,FareClass:undefined,DateOfTravel: undefined, Day: undefined, From: undefined, To: undefined,Status:undefined,DeptTime:undefined,ArrTime: undefined, ArrDate: undefined, Baggage: undefined, PNR: undefined,FlyingTime:undefined
    }
   
    boxClass = { happy: 'rating_icon ', average: 'rating_icon ', nothappy:'rating_icon '}
    state = {
        name:"",
        textOperated:"",
        recordData:{ ...this.blankData },
        postArrayWithKey:[],
        dataToProcess:"",
        processedData:"",
        processedArrayData:[],
        rating:"",
        boxRecord: { ...this.boxClass }
    }
    render() 
    { 
        return ( 
           < React.Fragment> 
           <div className="ml-0 pl-0 pr-0 mr-0">
                <div className="mb-2 pb-2 mt-1 pt-2 ml-0 pl-0 pr-0 mr-0 d-flex justify-content-center">
                <span className="col-12 d-flex justify-content-center"> <img className="logo" alt="" src={process.env.PUBLIC_URL + ""}/></span>
                </div>
                <div className="help_us mt-1 pt-2 mb-2 pb-2 ml-0 pl-0 mr-0 pr-0">
                <span className="col-12 ml-0 pl-0 mt-0 mb-0 pr-0 mr-0 d-flex justify-content-center text-center  text-primary text-bold">PNR PROCESSING</span>
                <span className="col-12 ml-0 pl-0 mt-0 mb-0 pr-0 mr-0 d-flex justify-content-center text-center text-bold font14"></span>
                <span className="col-12 d-flex justify-content-center"><img className="service_icon" alt="" src={process.env.PUBLIC_URL + ""}/></span>
                </div>
                <div className="row ml-0 pl-0 mt-3 mb-0 pr-0 mr-1 d-flex justify-content-center">
                    <span className="col-12 ml-0 pl-0 mt-0 mb-2 pr-0 mr-0 d-flex justify-content-center text-center text-bold"></span>
                    <span className="col-10 ml-1 pl-0 mt-0 mb-0 pr-0 mr-1 mt-3 d-flex justify-content-center">
                    <textarea rows="3" className=" form-control ml-0 pl-0 mr-o pr-0 mb-2  small text-sm"   value={this.state.dataToProcess} onChange={(event) => this.handleOnChangeReconRule(event)} />
                    </span>
                    <span className="col-12 ml-0 pl-0 mt-1 mb-0 pr-0 mr-0 d-flex justify-content-center">
                    <span className={this.state.boxRecord.happy+"col-4 text-center rating_cusror"} onClick={(event) => this.clear(event)}><button className="col-4 btn btn-primary btn-small" >Clear</button></span>
                    <span className={this.state.boxRecord.average+"col-4 text-center rating_cusror"} onClick={() => this.generate()}><button className="col-4 btn btn-success btn-small">Generate Report</button></span>
                    <span className={this.state.boxRecord.nothappy+"col-4 text-center rating_cusror"} onClick={() => this.onCopy()}> <button className="col-4 btn btn-secondary btn-small">Copy</button></span>
                    </span>
                    
                    <span className="col-10 ml-1 pl-0 mt-0 mb-0 pr-0 mr-1 mt-3 d-flex justify-content-center">
                    <textarea rows="3" className=" form-control ml-0 pl-0 mr-o pr-0 mb-2  small text-sm "  defaultValue={this.state.processedData}></textarea>
                    </span>
                    <span className="col-12 ml-0 pl-0 mt-1 mb-0 pr-0 mr-0 d-flex justify-content-center">
                    <span className="col-4 text-center rating_cusror" ><button className="col-4 btn btn-primary btn-small" onClick={() => this.exportToExcel()} >Export</button></span>
                    </span>
                </div>
                <div className="col-lg-12 row mt-2 pt-2 pb-2 ml-0 pl-0 mr-0 pr-0 d-flex justify-content-center">
                <span className="col-lg-12 bg_color font_14 pb-0 mb-0 pl-0 ml-0 pt-0 pl-2 text-center"> <b>Travel Partner Name:- Yatra For Business </b></span>
                <span className="col-lg-12 bg_color_itenary mt-0 pt-0 pb-0 ml-0 mr-0 pl-0 mr-0 pr-0 text-center"><b>Itinerary Name for:{this.state.name} </b></span>
                <span className="col-lg-12 mt-0 pt-0 pb-2 ml-1 pl-2 mr-0 pr-0">
                <GridTableComponent cols={this.getColsAll()} data={this.state.postArrayWithKey} disable={this.state.disableTable} _this={this} />
                </span>
                 </div>
            </div>
           </React.Fragment>
        )
       
}
getColsAll = () => {
    return [{ header: "AirlineName", key: "AirlineName", rowclasses : "text-center", headerclasses: "w-20 text-center"},
       { header: "FlightNumber", key: "FlightNumber",rowclasses : "text-center", headerclasses: "w-20 text-center" },
       { header: "CabinClass", key: "CabinClass", headerclasses: "w-20",rowclasses : "mr-2" },
       { header :"FareClass",key:"FareClass",rowclasses : "text-center", headerclasses: "w-15 text-center" },
       { header: "DateOfTravel", key: "DateOfTravel", rowclasses : "text-center", headerclasses: "w-20 text-center" },
       { header: "Day", key: "Day",rowclasses : "text-center", headerclasses: "w-20 text-center" },
       { header: "From", key: "From", headerclasses: "w-20",rowclasses : "mr-0" },
       { header: "To" , key: "To", headerclasses : "w-10",rowclasses : "pr-2 "},
       { header: "Status" , key: "Status", headerclasses : "w-10",rowclasses : "pr-2 "},
       { header: "DeptTime" , key: "DeptTime", headerclasses : "w-10",rowclasses : "pr-2 "},
       { header: "ArrTime" , key: "ArrTime", headerclasses : "w-10",rowclasses : "pr-2 "},
       { header: "ArrDate" , key: "ArrDate", headerclasses : "w-10",rowclasses : "pr-2 "},
       { header: "Baggage" , key: "Baggage", headerclasses : "w-10",rowclasses : "pr-2 "},
       { header: "PNR" , key: "PNR", headerclasses : "w-10",rowclasses : "pr-2 "},
      { header: "FlyingTime" , key: "FlyingTime", headerclasses : "w-10",rowclasses : "pr-2 "}
    ]
 }

handleOnChangeReconRule=(event)=>{
    let dataToProcess = this.state.dataToProcess;
    dataToProcess =event.target.value;
    this.setState({ dataToProcess }) ;
  }
clear = (event) => {
    console.log(this.state.dataToProcess)
      this.setState({dataToProcess:'',processedData:''})
      window.location.reload();
  }
generate=async ()=>{
    let postArray=[];
   // let rawString = this.state.dataToProcess.replace("*"," ");
    //console.log(rawString);
    let stringToProcess = this.state.dataToProcess.replace(/\*/g," ");
    console.log(stringToProcess);
    let arrayToProcess1 = stringToProcess.split("\n");
    let textOperated = arrayToProcess1.filter(s => s.includes('OPERATED'));
    let arrayToProcessRaw = arrayToProcess1.filter(s => !s.includes('OPERATED'));
    let arrayToProcess = [];
    let name1=arrayToProcessRaw.shift().replace(/\d\.+/g, ' - ');
    let name=name1.trim();
    //console.log(arrayToProcessRaw.length);
    for(let i=0;i<arrayToProcessRaw.length;i++){
        let proccesArrayString = arrayToProcessRaw[i].trim();
       let proccesArray1 = proccesArrayString.substr(proccesArrayString.indexOf(' ')+1).trim();
       let index = proccesArray1.indexOf(" ");
       let proccesArray="";
       if(index===2){
       proccesArray = proccesArray1.replace(" ","")
       }
       else{
       proccesArray = proccesArray1;
       }
        arrayToProcess.push(proccesArray);
    }
    this.setState({name,textOperated})
    console.log(name+" "+textOperated);
    console.log(arrayToProcess);
    
    for(let j=0;j<arrayToProcess.length;j++){
        let dataToProcess = {"key":arrayToProcess[j]};
        const requestOptions = {
            method: "POST",
            body: JSON.stringify(dataToProcess),
            headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept' : "application/json"
            } 
        }
        
        // const queryString = "page=" + ``1(page.number - 1) + "&size=" + page.size + "&searchText=" + this.state.searchText +"&sortDirection="+this.state.sortDirection +"&sortFieldName="+this.state.sortFieldName;
        //const queryString = "data" + dataToProcess;
       await fetch(process.env.REACT_APP_ROS_BASE_URL + '/Datadecode', requestOptions)
            .then(response => response.json())
            .then(res => {postArray.push(res["value"])})
           // .then(res => this.refreshData(res))
            .catch(err => console.error(err));    
            } 
           // console.log(postArray);
           this.setState({postArray});
            this.convertToText(postArray); 
            this.convertToKeyData(postArray);
        }

convertToKeyData=(data)=>{
  
    let recorddata=[];
    let eachdata =[];
    if (data && data.length > 0) {
        for (let i=0;i<data.length;i++) {
           let recordrawdata = {...this.blankData}
           eachdata = data[i];
           recordrawdata.AirlineName=eachdata[0];
            recordrawdata.FlightNumber=eachdata[1];
            recordrawdata.CabinClass=eachdata[2];
            
            recordrawdata.DateOfTravel=eachdata[3];
            recordrawdata.Day=eachdata[4];
            recordrawdata.From=eachdata[5];
            recordrawdata.To=eachdata[6];
            recordrawdata.Status=eachdata[7];
            recordrawdata.DeptTime=eachdata[8];
            recordrawdata.ArrTime=eachdata[9];
            recordrawdata.ArrDate=eachdata[10];
            recordrawdata.FareClass=eachdata[11];
            recordrawdata.PNR=eachdata[13];
            
            recorddata.push(recordrawdata);
        }
        console.log(recorddata);
        this.setState({postArrayWithKey:recorddata});
        console.log(this.state.postArrayWithKey);
}
}
convertToText=(processedArrayData)=>{
   console.log(processedArrayData);
   let processedData=[];
    for(let i=0;i<processedArrayData.length;i++){
    let data = processedArrayData[i].join(",");
    processedData.push(data);
    }
    console.log(processedData.length);
    let processedString="";
        processedString = processedData.join("\n");
    console.log(processedString);
    this.setState({processedData:processedString});
}

onCopy=() => {
    navigator.clipboard.writeText(this.state.processedData)

}

onExport=() => {
    navigator.clipboard.writeText(this.state.processedData)

}

componentDidMount() {
 }

 exportCSV(){
    //  console.log(this.state.record);
   
      var csvRow=[];
      var A=[['','','','','','','Travel Partner Name:- Yatra For Business','','','','','','','','']];
      A.push(['','','','','','','Itinerary Name for:- '+this.state.name,'','','','','','','','']);
     A.push(['AirlineName','FlightNumber','CabinClass','FareClass','DateOfTravel','Day','From', 'To','Status','DeptTime','ArrTime','ArrDate','Baggage','PNR','FlyingTime']);
      //var csvdata=this.state.processedData.split("\n");
      var csvdata=this.state.postArray;
     console.log(csvdata);
      if(csvdata.length===0){
       alert("No data to Export");
     }
    else{
      for(var i=0;i<csvdata.length;i++){
       let eachdata = csvdata[i];
       const AirlineName=eachdata[0];
       const FlightNumber=eachdata[1];
       const CabinClass=eachdata[2];
       const FareClass=eachdata[11];
       const DateOfTravel=eachdata[3];
       const Day=eachdata[4];
       const From=eachdata[5];
       const To=eachdata[6];
       const Status=eachdata[7];
       const DeptTime=eachdata[8];
       const ArrTime=eachdata[9];
       const ArrDate=eachdata[10];
       const PNR=eachdata[13];
       const Baggage ="";
       const FlyingTime=eachdata[14];
       A.push([AirlineName,FlightNumber,CabinClass,FareClass,DateOfTravel,Day,From,To,Status,DeptTime,ArrTime,ArrDate,Baggage,PNR,FlyingTime]);
      }
      for(var j=0;j<A.length;++j){
       csvRow.push(A[j].join(","));
      }
      var csvString=csvRow.join("%0A");
      //console.log(csvString);
      var a=document.createElement("a");
      a.href='data:attachment/csv,' + csvString;
      a.target="_Blank";
      a.download="Records.csv";
      document.body.appendChild(a);
      a.click()
    }
    }  
   /* exportToExcel=()=>{
     var A=[['Travel Partner Name:- Yatra For Business','','','','','','','','','','','','','','']];
     A.push(['Itenary Name for:- '+this.state.name,'','','','','','','','','','','','','','']);
      //var csvdata=this.state.processedData.split("\n");
      A.push(['AirlineName','FlightNumber','CabinClass','FareClass','DateOfTravel','Day','From', 'To','Status','DeptTime','ArrTime','ArrDate','Baggage','PNR','FlyingTime']);
      var csvdata=this.state.postArray;
     console.log(csvdata);
      if(csvdata.length===0){
       alert("No data to Export");
     }
    else{
      for(var i=0;i<csvdata.length;i++){
       let eachdata = csvdata[i];
       const AirlineName=eachdata[0];
       const FlightNumber=eachdata[1];
       const CabinClass=eachdata[2];
       const FareClass=eachdata[11];
       const DateOfTravel=eachdata[3];
       const Day=eachdata[4];
       const From=eachdata[5];
       const To=eachdata[6];
       const Status=eachdata[7];
       const DeptTime=eachdata[8];
       const ArrTime=eachdata[9];
       const ArrDate=eachdata[10];
       const PNR=eachdata[13];
       const Baggage ="";
       const FlyingTime="";
       A.push([AirlineName,FlightNumber,CabinClass,FareClass,DateOfTravel,Day,From,To,Status,DeptTime,ArrTime,ArrDate,Baggage,PNR,FlyingTime]);
      }
    }
    console.log(A);
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
     const fileExtension = ".xlsx";
     const fileName = "Report";
     var ws = XLSX.utils.json_to_sheet([A],{header:["0","1","2","3","4","5","6"], skipHeader:true});
    const merge = [ { s: { r: 1, c: 0 }, e: { r: 1, c: 14 }},{ s: { r: 2, c: 0 }, e: { r: 2, c: 14 }}];
     ws["!merges"] = merge;
     const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
     const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    
     const data = new Blob([excelBuffer], { type: fileType });
     FileSaver.saveAs(data, fileName + fileExtension);

    } */
     exportToExcel =async ()=> {
        var H=[['AirlineName','FlightNumber','CabinClass','FareClass','DateOfTravel','Day','From', 'To','Status','DeptTime','ArrTime','ArrDate','Baggage','PNR','FlyingTime']];
      var csvdata=this.state.postArray;
      var A=[];
     console.log(csvdata);
      if(csvdata.length===0){
       alert("No data to Export");
     }
    else{
      for(var i=0;i<csvdata.length;i++){
       let eachdata = csvdata[i];
       const AirlineName=eachdata[0];
       const FlightNumber=eachdata[1];
       const CabinClass=eachdata[2];
       const FareClass=eachdata[11];
       const DateOfTravel=eachdata[3];
       const Day=eachdata[4];
       const From=eachdata[5];
       const To=eachdata[6];
       const Status=eachdata[7];
       const DeptTime=eachdata[8];
       const ArrTime=eachdata[9];
       const ArrDate=eachdata[10];
       const PNR=eachdata[13];
       const Baggage ="";
       const FlyingTime=eachdata[14];
       A.push([AirlineName,FlightNumber,CabinClass,FareClass,DateOfTravel,Day,From,To,Status,DeptTime,ArrTime,ArrDate,Baggage,PNR,FlyingTime]);
      }
    }
    console.log(A);

        let workbook = await XLSXPOPULATE.fromBlankAsync();
         // Create a range of merged cells.
    const range = workbook.sheet(0).range("A1:O1");
    range.value("Travel Partner Name:- Yatra For Business");
    range.style({horizontalAlignment: "center", verticalAlignment: "center", fill:"a7d1f0",border:"thin"})
    range.merged(true);

    // Create another range of merged cells.
    const range2 = workbook.sheet(0).range("A2:O2");
    range2.value("Itenary Name for:"+this.state.name);
    range2.style({horizontalAlignment: "center", verticalAlignment: "center",fill:"e4bc0de7",border:"thin" });
    range2.merged(true);

    const range3 = workbook.sheet(0).range("A3:O3");
    range3.value(H);
    range3.style({fill:"a7d1f0",border:"thin"});

    workbook.sheet(0).cell("A4").value(A).style({border:"thin"});
    workbook.sheet(0).column("A").width(20);
    workbook.sheet(0).column("B").width(13);
    workbook.sheet(0).column("C").width(10);
    workbook.sheet(0).column("D").width(10);
    workbook.sheet(0).column("E").width(12);
    workbook.sheet(0).column("F").width(5);
    workbook.sheet(0).column("G").width(20);
    workbook.sheet(0).column("H").width(20);
    workbook.sheet(0).column("I").width(10);
    workbook.sheet(0).column("j").width(10);
    workbook.sheet(0).column("K").width(10);
    workbook.sheet(0).column("L").width(10);
    workbook.sheet(0).column("M").width(10);
    workbook.sheet(0).column("N").width(10);
    workbook.sheet(0).column("O").width(10);
    workbook.outputAsync()
    .then(function (blob) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // If IE, you must uses a different method.
            window.navigator.msSaveOrOpenBlob(blob, "out.xlsx");
        } else {    
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = "out.xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }
    });
     }
}
export default PNRProcessingComponent;