import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import DashboardComponent from "./navbar.component";


class BackendRouterComponent extends Component{
    render() { 
    return (
        <Router>
            <Switch>
                <Route path="/portal" component={DashboardComponent}/>
            </Switch>
        </Router>
    )
}
}

export default BackendRouterComponent;