import React, { Component } from 'react';
import './table.css';
import { isMobile } from '../../common/util'

class GridTableComponent extends Component {
   state = { 
      
    }
   render() { 
      return ( 
         <React.Fragment>
            {this.getTable()}
         </React.Fragment>
       );
   }

   getTable() {
      const mainDivClassNames = "row table-wrapper-scroll-y ml-0 mt-0 mr-0 pr-0 pl-0" + (this.props.mainDivClasses ? this.props.mainDivClasses : "");
      let tableElement = 
         <div className={mainDivClassNames}>
            <table className="table table-sm table-bordered">
            <thead className="tableBg">
               <tr>  
                  {this.createHeaderCols()}
               </tr>
            </thead>
            <tbody>
                  {this.loadData()}
            </tbody>
         </table >
         </div>
      return tableElement;
   }

   createHeaderCols() {
      let headertrele = [];
      const inHeaderCols = this.props.cols;
     /*headertrele.push(<th key="sr" className="font-weight-bold text-right" >SR.NO.</th>)*/
      headertrele.push(inHeaderCols.map((itm) => this.getHeaderCols(itm)))
      return headertrele;
   }

   getHeaderCols(itm) {
      let thclasses = " font-weight-bold text-left"
      thclasses += thclasses + itm.headerclasses
      return (
         <th key={itm.key} className={ thclasses } >{itm.header}</th>
      )
   }

   loadData() {
      const data = this.props.data;
     
      
      let row = "";
      let i = 0;
      if (data) {
         let selectedRowSr = this.props._this.state.selectedRowSr
         if (!selectedRowSr) {
            selectedRowSr = 1
            //this.props._this.setState({selectedRowSr})
         }
         row = data.map(itm => {
            i++;
            const j = i
            let rowclass = ""
           // if (j === selectedRowSr) {
             //  rowclass="active"
            //}
            return (<tr valign= "middle" key={i} className={rowclass} onClick={(event) => this.onRowClick(event, itm, j,1)} onDoubleClick= {(event) => this.onRowClick(event, itm,j,2 ) }>{
               this.createDataCols(itm, i)
            }</tr>)
         })
      }
      return row;
   }

   createDataCols(itm,i) {
      const inHeaderCols = this.props.cols;
      let collist = []
      //const key = "row-" + i;
      /*collist.push(<td key={key} className="small text-right">{i}</td>)*/
      collist.push(inHeaderCols.map(col => {
               return this.createDataRow(col, itm);
      }))
      return collist;    
   }

   createDataRow(col, itm) {
      const classes = "small vertical-align-middle " + (col.rowclasses ? col.rowclasses : "");
      const key = "row-" + col.key;
      return <td  key={key} className ={ classes }>{this.getValue(itm,col)}</td>
   }

   getValue(item,col) {
      const key=col.key
      const fieldKey = key.split(".")
      let result = item[fieldKey[0]];
      for (let i = 1; i < fieldKey.length && result; i++) {
         result = result[fieldKey[i]];
      }
      if(col.fnValue) {
         result=col.fnValue(result);
      }
      return result;
   }

   onRowClick = (event, item, i, type) => {
      if (isMobile()) {
         if (!this.props.disable) {
            if (this.props.onRowClick) {
               this.props.onRowClick({ ...item });
            } else if (this.props.onRowDblClick) {
               this.props.onRowDblClick({ ...item })
            }
         }
      } else {
         if (type === 2 && this.props.onRowDblClick && (!this.props.disable)) {
            this.props.onRowDblClick({ ...item });
            this.props._this.setState({ selectedRowSr: i })
         } else if (type === 1 && this.props.onRowClick && (!this.props.disable)) {
            this.props.onRowClick({ ...item });
            this.props._this.setState({ selectedRowSr: i })
         }
      }
   }
}
 

export default GridTableComponent;