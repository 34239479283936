import React, { Component } from 'react';
import './feedback.css';
//import CSVDownloadComponent from './csvdownload.component ';
import GridTableComponent from './GridTable/grid.table.component';
//import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import * as XLSX from "xlsx/xlsx.mjs";
import * as XLSXPOPULATE from "xlsx-populate";
import * as FileSaver from "file-saver";

class AddValueComponent extends Component{
    blankData= {
        AirlineName: undefined, FlightNumber: undefined, CabinClass: undefined, Amount: undefined,FareClass:undefined,DateOfTravel: undefined, Day: undefined, From: undefined, To: undefined,Status:undefined,DeptTime:undefined,ArrTime: undefined, ArrDate: undefined, Baggage: undefined, PNR: undefined,FlyingTime:undefined
    }
   
    boxClass = { happy: 'rating_icon ', average: 'rating_icon ', nothappy:'rating_icon '}
    state = {
        name:"",
        textOperated:"",
        recordData:{ ...this.blankData },
        postArrayWithKey:[],
        dataToProcess:"",
        processedData:"",
        processedArrayData:[],
        rating:"",
        boxRecord: { ...this.boxClass }
    }
    render() 
    { 
        return ( 
           < React.Fragment> 
           <div className="ml-0 pl-0 pr-0 mr-0">
                <div className="mb-2 pb-2 mt-1 pt-2 ml-0 pl-0 pr-0 mr-0 d-flex justify-content-center">
                <span className="col-12 d-flex justify-content-center"> <img className="logo" alt="" src={process.env.PUBLIC_URL + ""}/></span>
                </div>
                <div className="help_us mt-1 pt-2 mb-2 pb-2 ml-0 pl-0 mr-0 pr-0">
                <span className="col-12 ml-0 pl-0 mt-0 mb-0 pr-0 mr-0 d-flex justify-content-center text-center  text-primary text-bold">ADD NEW VALUES</span>
                </div>
                <div className="row ml-0 pl-0 mt-3 mb-0 pr-0 mr-1 d-flex justify-content-center">
                    <div className="row col-6 ml-0 pl-0 mt-3 mb-0 pr-0 mr-1 d-flex justify-content-center">
                    <span className="control-label text-bold col-6 align-right ml-0 mr-0 pl-0 pr-0">Type:</span>
                    <span className="control-label text-bold col-4 ml-0 mr-0 pl-0 pr-0">
                    <select className="form-control col-4 small ml-0 mr-0 mb-2">
                           <option value="">-</option>
                           <option value="A">Airline</option>
                           <option value="C">Code</option>
                           </select>
                    </span>

                    <span className="control-label text-bold col-6 align-right mt-1 ml-0 mr-0 pl-0 pr-0">Key:</span>
                    <span className="control-label text-bold col-4 ml-0 mr-0 pl-0 pr-0">
                    <input type="textbox" className=" form-control ml-0 mr-0 pr-0 pl-1 mt-0 col-lg-11 col-md-12 col-sm-12 col-12 "></input>    
                    </span>


                    <span className="control-label text-bold col-6 float-right mt-2 ml-0 mr-0 pl-0 pr-0">Value:</span>
                    <span className="control-label text-bold col-4 ml-0 mr-0 pl-0 pr-0">
                    <input type="textbox" className="form-control ml-0 mr-0 pr-0 pl-1 mt-2 col-lg-11 col-md-12 col-sm-12 col-12 "></input>    
                    </span>
                    
                    <span className="col-4 text-center rating_cusror" ><button className="col-4 btn btn-primary btn-small" onClick={() => this.exportToExcel()} >Save</button></span>
                   
                    </div>
                    
                </div>
            </div>
           </React.Fragment>
        )
       
}

}
export default AddValueComponent;